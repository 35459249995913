class Service {
  /**
   * Constructor
   *
   * @constructor
   * @param {any} fetcher
   * @param {string} url
   */
  constructor (fetcher, url) {
    this.fetcher = fetcher;
    this.url = url;
  }

  async get (params, headers) {
    const { url } = this;

    return this.fetcher.get(url, { params, headers });
  }

  async post (data, params, headers) {
    const { url } = this;

    return this.fetcher.post(url, data, { params, headers });
  }
}

export default {};
export { Service };
