import React from 'react';
import PropTypes from 'prop-types';

import { SpinnerRoundFilled } from 'spinners-react';
import styled from '@emotion/styled';


/**
 * Full screen loading screen
 *
 * @param {string} props.title
 * @return {JSX.Element}
 */
function FullScreenLoading ({ title }) {
  return (
    <Wrapper className="fullscreen-loading">
      <div className="fullscreen-loading-content">
        <SpinnerRoundFilled size={95} speed={80} thickness={140} />
        <h1 className="title">{title}</h1>
      </div>
    </Wrapper>
  );
}

FullScreenLoading.propTypes = {
  title: PropTypes.string,
};

FullScreenLoading.defaultProps = {
  title: 'loading',
};

const Wrapper = styled.div``;

export default FullScreenLoading;
