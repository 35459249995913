import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import cx from 'classnames';


/**
 * Section component
 *
 * @param {JSX.Element} props.children
 * @param {boolean} props.paddingless
 * @param {boolean} props.marginless
 * @param {boolean} props.marginTopless
 * @param {boolean} props.centeredAlign
 * @return {JSX.Element}
 */
function Section ({ children, paddingless, marginless, marginTopless, centeredAlign }) {
  return (
    <Wrapper
      className={cx(
        'section',
        { 'is-paddingless': paddingless },
        { 'is-marginless': marginless },
        { 'is-marginless-top': marginTopless },
        { 'is-centered-align': centeredAlign },
      )}
    >
      {children}
    </Wrapper>
  );
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
  paddingless: PropTypes.bool,
  marginless: PropTypes.bool,
  marginTopless: PropTypes.bool,
  centeredAlign: PropTypes.bool,
};

Section.defaultProps = {
  paddingless: false,
  marginless: false,
  marginTopless: false,
  centeredAlign: false,
};

const Wrapper = styled.div``;

export default Section;
