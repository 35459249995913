class ThemeService {
  /**
   * Constructor
   *
   * @constructor
   * @param {any} fetcher
   * @param {string} url
   */
  constructor (fetcher, url) {
    this.fetcher = fetcher;
    this.url = url || '/theme.json';
  }

  /**
   * Get theme
   *
   * @param {'terminal21' | 'fashionisland'} target
   * @return {Promise<any>}
   */
  async get (target) {
    const { data, ...args } = await this.fetcher.get(this.url);

    return { ...args, data: data[target] };
  }
}

export default {};
export { ThemeService };
