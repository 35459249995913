import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

/**
 * Highlight text component
 *
 * @param {string} text
 * @param {string} highlight
 * @return {JSX.Element}
 */
function HighlightText ({ text, highlight }) {
  const parts = text.split(new RegExp(`(${highlight})`, 'gi'));

  return (
    <span>
      {parts.map((part, index) => (
        <span
          key={index.toString()}
          className={cx(
            'highlight',
            { 'highlight-text': part.toLowerCase() === highlight.toLowerCase() },
          )}
        >
          {part}
        </span>
      ))}
    </span>
  );
}

HighlightText.propTypes = {
  text: PropTypes.string,
  highlight: PropTypes.string,
};

HighlightText.defaultProps = {
  text: '',
  highlight: '',
};

export default HighlightText;
