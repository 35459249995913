import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';


const variantHashMap = {
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  span: 'span',
  p: 'p',
  a: 'a',
  b: 'b',
  i: 'i',
  label: 'label',
  strong: 'strong',
  title: 'h1',
  subtitle: 'h2',
  caption: 'span',
};

/**
 * Typography component
 * Copycat from Typography of Material UI
 *
 * @param {string} children
 * @return {JSX.Element}
 */
function Typography ({ children, variant, color, display, align }) {
  const Component = variantHashMap[variant];

  return (
    <Component
      className={cx(
        'typography',
        { [`is-${variant}`]: variant },
        { [`is-color-${color}`]: color },
        { [`is-display-${display}`]: display },
        { [`is-align-${align}`]: align },
      )}
    >
      {children}
    </Component>
  );
}

Typography.propTypes = {
  children: PropTypes.string.isRequired,
  variant: PropTypes.oneOf([ 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'span', 'p', 'a', 'b', 'i', 'label', 'strong', 'title', 'subtitle', 'caption' ]),
  color: PropTypes.oneOf([ '', 'primary', 'info', 'light', 'dark' ]),
  display: PropTypes.oneOf([ '', 'block', 'inline' ]),
  align: PropTypes.oneOf([ 'inherit', 'left', 'center', 'right', 'justify' ]),
};

Typography.defaultProps = {
  variant: 'p',
  color: '',
  display: '',
  align: 'inherit',
};

export default Typography;
