import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import { useStore } from '~/hooks';

import Field from '../Field';
import Banner from '../Banner';


/**
 * Input with banner component
 *
 * @param {string} props.banner
 * @param {string} props.label
 * @param {'primary' | 'info' | 'light' | 'dark'} props.labelVariant
 * @param {string} props.value
 * @param {string} props.placeholder
 * @param {function} props.onChange
 * @param {function} props.onClick
 * @param {string} props.iconLeft
 * @param {string} props.iconRight
 * @param {boolean} props.readonly
 * @return {JSX.Element}
 */
const InputBanner = forwardRef(({
  banner,
  label,
  labelVariant,
  value,
  placeholder,
  onChange,
  onClick,
  iconLeft,
  iconRight,
  readonly,
}, ref) => {
  const { themeStore } = useStore();

  return (
    <Wrapper theme={themeStore.theme} className="input-banner">
      <Banner img={banner} />
      <div className="overlap">
        <Field>
          {label && <Field.Label variant={labelVariant}>{label}</Field.Label>}
          <div
            role="button"
            tabIndex="0"
            onKeyPress={() => {}}
            onClick={onClick}
          >
            <Field.Input
              ref={ref}
              value={value}
              placeholder={placeholder}
              iconLeft={iconLeft}
              iconRight={iconRight}
              onChange={onChange}
              readonly={readonly}
            />
          </div>
        </Field>
      </div>
    </Wrapper>
  );
});

InputBanner.propTypes = {
  banner: PropTypes.string,
  label: PropTypes.string,
  labelVariant: PropTypes.oneOf([ '', 'primary', 'info', 'light', 'dark' ]),
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  readonly: PropTypes.bool,
};
InputBanner.defaultProps = {
  banner: '',
  label: '',
  labelVariant: '',
  value: '',
  placeholder: '',
  onChange () {},
  onClick () {},
  iconLeft: '',
  iconRight: '',
  readonly: false,
};

const Wrapper = styled.div``;

export default InputBanner;
