import React, { lazy, Suspense } from 'react';
import { createBrowserHistory } from 'history';
import { Router, Route } from 'react-router-dom';

import { Home } from '~/screens';
import { LoadingSwitch } from '~/explicit-components';
import { Loading } from '~/components';


const { PUBLIC_URL } = process.env;

const router = {
  basename: PUBLIC_URL,
  getUserConfirmation: (message, callback) => callback(window.confirm(message)), /** A function to use to confirm navigation with the user (see below) */
  routes: [
    {
      path: '/:organisation',
      name: 'home',
      component: Home,
    },
    {
      path: '/:organisation/search',
      name: 'search',
      component: lazy(() => import(/* webpackChunkName: "search" */ '~/screens/Search')),
    },
    {
      path: '/:organisation/search/result',
      name: 'search-result',
      component: lazy(() => import(/* webpackChunkName: "search-result" */ '~/screens/SearchResult')),
    },
    {
      name: 'not-found',
      component: lazy(() => import(/* webpackChunkName: "not-found" */ '~/errors/NotFound')),
    },
  ],
};

/**
 * Application root
 *
 * @return {JSX.Element}
 */
function App () {
  const { routes, basename, getUserConfirmation } = router;
  const history = createBrowserHistory({ basename, getUserConfirmation });

  const renderRouteWithChild = (route, index) => {
    const { path, exact = true, name, children } = route;

    return (
      <Route
        key={name || index.toString()}
        path={path}
        exact={children ? false : exact}
        render={(props) => (
          <route.component
            {...props} // eslint-disable-line react/jsx-props-no-spreading
            routerView={() => (children ? children.map((child, index) => renderRouteWithChild(child, index)) : <></>)}
          />
        )}
      />
    );
  };

  return (
    <Suspense fallback={<div />}>
      <Router history={history}>
        <LoadingSwitch renderLoading={() => <Loading.FullScreen />}>
          {routes.map((route, index) => renderRouteWithChild(route, index))}
        </LoadingSwitch>
      </Router>
    </Suspense>
  );
}

export default App;
