import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';


/**
 * Label of Field component
 *
 * @param {JSX.Element} props.children
 * @param {'small' | 'normal' | 'medium' | 'large'} props.size
 * @param {'primary' | 'info' | 'light' | 'dark'} props.variant
 * @return {JSX.Element}
 */
function Label ({ children, size, variant }) {
  return (
    <label
      htmlFor="input"
      className={cx(
        'label',
        { [`is-${size}`]: size },
        { [`is-${variant}`]: variant },
      )}
    >
      {children}
    </label>
  );
}

Label.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf([ '', 'small', 'normal', 'medium', 'large' ]),
  variant: PropTypes.oneOf([ '', 'primary', 'info', 'light', 'dark' ]),
};

Label.defaultProps = {
  size: '',
  variant: '',
};

export default Label;
