import { createContext } from 'react';

import { configure } from 'mobx';

import config from '~/lib/Config';
import fetcher from '~/lib/Fetcher';

import { OrganisationStore } from '~/stores/OrganisationStore';
import { ThemeStore } from '~/stores/ThemeStore';
import { LanguageStore } from '~/stores/LanguageStore';
import { SearchStore } from '~/stores/SearchStore';
import { ServiceStore } from '~/stores/ServiceStore';


// don't allow state modifications outside actions
// see more https://mobx.js.org/refguide/api.html#enforceactions
configure({ enforceActions: 'observed' });

export default {};
export const StoreContext = createContext({
  organisationStore: new OrganisationStore(),
  themeStore: new ThemeStore(),
  languageStore: new LanguageStore(config.DEFAULT_LANGUAGE),
  searchStore: new SearchStore(),
  serviceStore: new ServiceStore(fetcher),
});
