import React from 'react';
import PropTypes from 'prop-types';

import { useObserver } from 'mobx-react-lite';

import styled from '@emotion/styled';
import cx from 'classnames';

import { useStore, useTranslate } from '~/hooks';
import { Typography } from '~/components';


/**
 * Branch filter component
 *
 * @param {string} props.title
 * @param {any[]} props.branches
 * @param {any} props.active
 * @param {string} props.activeKey
 * @param {function} props.onClick
 * @return {JSX.Element}
 */
function BranchFilter ({ title, branches, active, activeKey, onClick }) {
  // const { liff } = useLIFF();
  const { themeStore } = useStore();
  const { translate } = useTranslate();

  const disabledSlugs = [];
  // const disabledSlugs = [ 'thepromenade', 'terminal21-korat', 'terminal21-pattaya' ];

  // const handleClick = (branch) => {
  //   liff.openWindow({
  //     url: `${organisationStore.webDirectoryEndpoint}/en/${branch.slug}/explore`,
  //     external: true,
  //   });
  // };

  const renderFilterItem = (branch) => (
    <div
      className={cx(
        'filter-item',
        { 'is-active': active[activeKey] === branch[activeKey] },
        { 'is-disabled': disabledSlugs.indexOf(branch.slug) !== -1 },
      )}
      onClick={() => onClick(branch)}
      role="button"
      tabIndex="0"
      onKeyPress={() => { }}
      key={branch.id}
    >
      {/* <a target="_blank" rel="noopener noreferrer" href={`${endpoint}/en/${branch.slug}/explore`}> */}
      <figure className="filter-item-img">
        <img src={branch.img} alt={branch.img} />
      </figure>
      {/* </a> */}
      <p className="filter-item-title">{translate(branch.title)}</p>
    </div>
  );

  return useObserver(() => (
    <Wrapper theme={themeStore.theme} className="branch-filter">
      {title && (
        <div className="branch-filter-title">
          <Typography variant="subtitle">{title}</Typography>
        </div>
      )}
      <div className="filter-container">
        {branches.map(renderFilterItem)}
      </div>
    </Wrapper>
  ));
}

const Wrapper = styled.div`
  &.branch-filter {
    .filter-container {
      .filter-item {
        flex-basis: 49%;
        .filter-item-img {
          border-color: transparent;
        }

        &.is-active {
          .filter-item-img {
            outline-color: ${({ theme }) => theme.primary};
          }
        }
      }
    }
  }
`;

BranchFilter.propTypes = {
  title: PropTypes.string,
  branches: PropTypes.arrayOf(PropTypes.shape()),
  active: PropTypes.shape(),
  activeKey: PropTypes.string,
  onClick: PropTypes.func,
};

BranchFilter.defaultProps = {
  title: '',
  branches: [],
  active: {},
  activeKey: 'id',
  onClick () {},
};

export default BranchFilter;
