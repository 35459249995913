import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import Icon from '../Icon';

const ListItem = ({
  iconLeft,
  iconRight,
  children,
  size,
  styleIconLeft,
  styleIconRight,
  onClickItem,
  onClickIconLeft,
  onClickIconRight,
  borderless,
}) => {
  const renderIcon = (name, position, onClick, style) => (
    <div
      onClick={onClick}
      onKeyPress={() => {}}
      role="button"
      tabIndex="0"
      className={cx(
        'list-item-icon',
        { [`is-${position}`]: position },
        { 'is-clickable': onClick },
      )}
      style={style}
    >
      <Icon name={name} size={size} />
    </div>
  );

  const handleClickIconLeft = (e) => {
    e.stopPropagation(); onClickIconLeft();
  };
  const handleClickIconRight = (e) => {
    e.stopPropagation(); onClickIconRight();
  };

  return (
    <div
      role="button"
      tabIndex="0"
      className={cx(
        'list-item',
        { 'has-icon-left': iconLeft },
        { 'has-icon-right': iconRight },
        { 'is-borderless': borderless },
      )}
      onKeyPress={() => {}}
      onClick={onClickItem}
    >
      {children && <div className="list-item-content">{children}</div>}
      {iconLeft && renderIcon(iconLeft, 'left', handleClickIconLeft, styleIconLeft)}
      {iconRight && renderIcon(iconRight, 'right', handleClickIconRight, styleIconRight)}
    </div>
  );
};

ListItem.propTypes = {
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf([ '', 'small', 'normal', 'medium', 'large' ]),
  styleIconLeft: PropTypes.shape({}),
  styleIconRight: PropTypes.shape({}),
  onClickItem: PropTypes.func,
  onClickIconLeft: PropTypes.func,
  onClickIconRight: PropTypes.func,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  borderless: PropTypes.bool,
};

ListItem.defaultProps = {
  size: '',
  styleIconLeft: {},
  styleIconRight: {},
  onClickItem () {},
  onClickIconLeft () {},
  onClickIconRight () {},
  iconLeft: '',
  iconRight: '',
  borderless: true,
};

export default ListItem;
