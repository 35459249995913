import React from 'react';
import PropTypes from 'prop-types';

import { HighlightText, ListItem } from '~/components';

import { useTranslate } from '~/hooks';

const SearchResultItem = ({ item, searchValue, onClick, onClickIconRight }) => {
  const { translate } = useTranslate();
  return (
    <ListItem
      iconRight="ArrowRoundBack"
      styleIconRight={{ transform: 'rotate(45deg)' }}
      size="medium"
      onClickItem={onClick}
      onClickIconRight={onClickIconRight}
    >
      <HighlightText text={translate(item.title)} highlight={searchValue} />
    </ListItem>
  );
};

SearchResultItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.shape({
      th: PropTypes.string,
      en: PropTypes.string,
    }),
  }),
  searchValue: PropTypes.string,
  onClick: PropTypes.func,
  onClickIconRight: PropTypes.func,
};

SearchResultItem.defaultProps = {
  item: {},
  searchValue: '',
  onClick () {},
  onClickIconRight () {},
};

export default SearchResultItem;
