import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';

import { useObserver } from 'mobx-react-lite';

import Collapse from '@kunukn/react-collapse';
import cx from 'classnames';

import { useStore, useClickOutside } from '~/hooks';

import Icon from '../Icon';


/**
 * Collapsible component
 *
 * @param {string} props.title
 * @param {string} props.coverText
 * @param {JSX.Element} props.renderContent
 * @return {JSX.Element}
 */
function Collapsible ({
  title,
  coverText,
  renderContent,
}) {
  const [ active, setActive ] = useState(false);
  const { themeStore } = useStore();
  const ref = useRef();

  useClickOutside(ref, () => setActive(false));

  return useObserver(() => (
    <Wrapper
      theme={themeStore.theme}
      className={cx(
        'collapse',
        { 'is-expanded': active, 'is-collapsed': !active },
      )}
    >
      <div
        className="collapse-header"
        onClick={() => setActive((s) => !s)}
        role="button"
        tabIndex="0"
        onKeyPress={() => { }}
        ref={ref}
      >
        <div className="collapse-title-container">
          <span className="collapse-title">{title}</span>
        </div>
        <div className="collapse-arrow-container">
          <div className="collapse-arrow">
            <Icon name="ArrowDown" />
          </div>
        </div>
      </div>

      <Collapse
        elementType="section"
        isOpen={active}
        aria-hidden={active ? 'false' : 'true'}
        className="collapse-content"
      >
        {renderContent}
      </Collapse>
      <div className="collapse-cover">
        <span className="collapse-cover-text">{coverText}</span>
      </div>
    </Wrapper>
  ));
}

const Wrapper = styled.div`
  ${({ theme }) => `
    &.collapse {
      border-bottom-color: ${theme.border};

      .collapse-header {
        border-bottom-color: ${theme.border};
      }

      .collapse-arrow-container {
        .collapse-arrow {
          color: ${theme.primary};
        }
      }

      .collapse-cover {
        &-text {
          color: ${theme.textHelper};
        }
      }
    }
  `}
`;


Collapsible.propTypes = {
  title: PropTypes.string,
  coverText: PropTypes.string,
  renderContent: PropTypes.node.isRequired,
};
Collapsible.defaultProps = {
  title: '',
  coverText: '',
};

export default Collapsible;
