import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import styled from '@emotion/styled';
import { useObserver } from 'mobx-react-lite';

import { useStore } from '~/hooks';

import Icon from '../Icon';

function Input ({
  label,
  iconRight,
  iconLeft,
  onChange,
  placeholder,
  value,
  onKeyDown,
}) {
  const { themeStore } = useStore();

  return useObserver(() => (
    <Wrapper theme={themeStore.theme} className="input">
      { label && (
        <label htmlFor="input" className="label">
          {label}
        </label>
      )}
      <div className={cx(
        'text-input',
        { 'has-icons-left': iconLeft, 'has-icons-right': iconRight },
      )}
      >
        <input
          id="input"
          onChange={onChange}
          placeholder={placeholder}
          value={value}
          onKeyDown={onKeyDown}
        />
        {
        iconLeft && (
          <span className="input-icon is-left">
            <Icon name={iconLeft} />
          </span>
        )
      }
        {
        iconRight && (
          <span className="input-icon is-right">
            <Icon name={iconRight} />
          </span>
        )
      }

      </div>
    </Wrapper>
  ));
}

const Wrapper = styled.div`
  ${({ theme }) => `
    .input {
      color: ${theme.textDisabled};

      .label {
        color: ${theme.text};
      }
      
      .text-input {
        border: 1px solid ${theme.border};
        background-color: ${theme.background};
        box-shadow: 1px 1px 5px ${theme.dropShadow};

        &:hover {
          box-shadow: 1px 1px 5px ${theme.dropShadowActive};
        }

        input::-webkit-input-placeholder {
          color: ${theme.textDisabled};
        }
      }
  `}
`;

Input.propTypes = {
  label: PropTypes.string,
  iconRight: PropTypes.string,
  iconLeft: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  onKeyDown: PropTypes.func,
};

Input.defaultProps = {
  label: '',
  iconRight: '',
  iconLeft: '',
  className: '',
  onChange () {},
  placeholder: '',
  value: '',
  onKeyDown () {},
};

export default Input;
