const {
  PUBLIC_URL,

  REACT_APP_DEFAULT_LANGUAGE,

  REACT_APP_TERMINAL_URL,
  REACT_APP_FASHION_URL,

  REACT_APP_TERMINAL_21_ASOK_API_KEY,
  REACT_APP_TERMINAL_21_KORAT_API_KEY,
  REACT_APP_TERMINAL_21_PATTAYA_API_KEY,
  REACT_APP_TERMINAL_21_RAMA3_API_KEY,

  REACT_APP_THE_PROMENADE_API_KEY,
  REACT_APP_FASHIONISLAND_API_KEY,

  REACT_APP_FASHONISLAND_LIFF_ID,
  REACT_APP_TERMINAL_21_LIFF_ID,

  REACT_APP_FASHONISLAND_STAT_SERVICE,
  REACT_APP_TERMINAL_21_STAT_SERVICE,

  REACT_APP_FASHONISLAND_DIRECTORY_URL,
  REACT_APP_TERMINAL_21_DIRECTORY_URL,
} = process.env;

export default {
  PUBLIC_URL,

  DEFAULT_LANGUAGE: REACT_APP_DEFAULT_LANGUAGE,

  TERMINAL_21_URL: REACT_APP_TERMINAL_URL,
  FASHIONISLAND_URL: REACT_APP_FASHION_URL,

  TERMINAL_21_ASOK_API_KEY: REACT_APP_TERMINAL_21_ASOK_API_KEY,
  TERMINAL_21_KORAT_API_KEY: REACT_APP_TERMINAL_21_KORAT_API_KEY,
  TERMINAL_21_PATTAYA_API_KEY: REACT_APP_TERMINAL_21_PATTAYA_API_KEY,
  TERMINAL_21_RAMA3_API_KEY: REACT_APP_TERMINAL_21_RAMA3_API_KEY,

  THE_PROMENADE_API_KEY: REACT_APP_THE_PROMENADE_API_KEY,
  FASHIONISLAND_API_KEY: REACT_APP_FASHIONISLAND_API_KEY,

  FASHIONISLAND_LIFF_ID: REACT_APP_FASHONISLAND_LIFF_ID,
  TERMINAL_21_LIFF_ID: REACT_APP_TERMINAL_21_LIFF_ID,

  FASHONISLAND_STAT_SERVICE: REACT_APP_FASHONISLAND_STAT_SERVICE,
  TERMINAL_21_STAT_SERVICE: REACT_APP_TERMINAL_21_STAT_SERVICE,

  FASHONISLAND_DIRECTORY_URL: REACT_APP_FASHONISLAND_DIRECTORY_URL,
  TERMINAL_21_DIRECTORY_URL: REACT_APP_TERMINAL_21_DIRECTORY_URL,
};
