import { observable, action, runInAction } from 'mobx';

import { OrganisationService } from '~/services/OrganisationService';


class OrganisationStore {
  @observable
  name = '';

  @observable
  banner = {};

  @observable
  project = {};

  @observable
  branches = [];

  @action.bound
  async fetchOrganisation (target) {
    try {
      const { data } = await this.organisationService.get(target);

      runInAction(() => {
        this.name = target;
        this.banner = data.banner;
        this.project = data.project;
        this.branches = data.branches;
        this.liffId = data.liffId;
        this.helmet = data.helmet;
        // this.webDirectoryEndpoint = data.webDirectoryEndpoint;
      });

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  @action.bound
  init (fetcher) {
    this.organisationService = new OrganisationService(fetcher);
  }
}

export default {};
export { OrganisationStore };
