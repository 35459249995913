import useStore from './useStore';


/**
 * Use translate hooks
 */
function useTranslate () {
  const { languageStore } = useStore();

  return {
    /**
     * Translate
     *
     * @param {any} [param={}]
     * @return {string}
     */
    translate (param = {}) {
      return param[languageStore.language];
    },
  };
}

export default useTranslate;
