class UtmService {
  /**
   * Constructor
   *
   * @constructor
   * @param {any} fetcher
   * @param {string} url
   */
  constructor (fetcher) {
    this.fetcher = fetcher;
    this.url = '/utm';
  }

  /**
   * Get theme
   *
   * @param {'terminal21' | 'fashionisland'} target
   * @return {Promise<any>}
   */
  async create (data) {
    await this.fetcher.post(this.url, data);
  }
}

export default {};
export { UtmService };
