import { useEffect } from 'react';

function useClickOutside (ref, effect) {
  const listener = (e) => {
    if (ref.current && !ref.current.contains(e.target)) effect(e);
  };

  useEffect(() => {
    document.addEventListener('click', listener);

    return () => {
      document.removeEventListener('click', listener);
    };
  }, []);
}

export default useClickOutside;
