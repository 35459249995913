/**
 * Join the provided path with public url
 *
 * @param {string} path - path/to/file
 * @return {string}
 */
function join (path) {
  const { PUBLIC_URL } = process.env;

  return PUBLIC_URL + path;
}

export default {
  join,
};
