import React, { useEffect, useRef, useState } from 'react';
import { useParams, useHistory, useLocation } from 'react-router-dom';

import styled from '@emotion/styled';
import { useObserver } from 'mobx-react-lite';
import isEmpty from 'lodash.isempty';

import { useStore, useTranslate } from '~/hooks';
import publicUrl from '~/lib/PublicUrl';

import { InputBanner, ShopListItem, ListContainer, Collapse } from '~/components';
import { BranchFilter, CategoryFilter } from '~/explicit-components';


/**
 * Search shop screen
 *
 * @return {JSX.Element}
 */
function SearchShop () {
  const keywordRef = useRef(null);

  const [loading] = useState(false);

  const search = useParams();
  const history = useHistory();
  const location = useLocation();

  const { themeStore, searchStore, organisationStore, serviceStore } = useStore();
  const { translate } = useTranslate();

  const param = new URLSearchParams(location.search);

  const setFilterShops = () => {
    const filteredShops = serviceStore.entities.reduce((acc, entity) => {
      const keywordRegExp = new RegExp(`^${searchStore.keyword.toLowerCase()}`, 'i');
      const isContainKeyword = keywordRegExp.test(entity.title.en.toLowerCase());
      const hasBranch = searchStore.hasBranch();
      const isInBranch = hasBranch ? entity.projectId === searchStore.branch.id : true;
      const hasActiveCategory = searchStore.hasActiveCategory();
      const isInActiveCategory = hasActiveCategory ? entity.categories.indexOf(searchStore.activeCategory.id) : true;
      if (!isContainKeyword || (hasBranch && !isInBranch) || (hasActiveCategory && !isInActiveCategory)) {
        return acc;
      }
      return [
        ...acc,
        entity,
      ];
    }, []);
    searchStore.setShops(filteredShops);
  };

  useEffect(() => {
    const regexp = new RegExp(`^${searchStore.keyword.toLowerCase()}`, 'i');
    searchStore.setShops(serviceStore.entities.filter((entity) => regexp.test(entity.title.en.toLowerCase())));
  }, [searchStore.keyword]);

  const getEntities = async () => {
    await serviceStore.fetchEntities(searchStore.branch, { type: 'shops', keywords: [searchStore.keyword], $populate: [ 'logo', 'unit', 'project', 'categories', 'floor' ] });
    setFilterShops();
  };

  const getCategories = async () => {
    await serviceStore.fetchCategories(searchStore.branch);
    const filteredCategories = searchStore.hasBranch() ? serviceStore.categories.filter((category) => `${category.projectId}` === `${searchStore.branch.id}`) : serviceStore.categories;
    searchStore.setCategories(filteredCategories);
  };

  const init = async () => {
    const projects = await serviceStore.fetcherProjects();

    const params = new URLSearchParams(location.search);
    const keyword = params.get('keyword');
    const branch = params.get('branch');

    if (branch && isEmpty(searchStore.branch)) {
      searchStore.setBranch(projects.find((project) => translate(project.title) === branch) || searchStore.branch);
    }
    if (keyword && !searchStore.keyword) {
      searchStore.setKeyword(keyword);
      await getEntities();
    }
    await getCategories();
  };

  useEffect(() => {
    init();
  }, []);

  const handlerOnClickBranch = async (branch) => {
    const params = new URLSearchParams(location.search);
    const title = translate(branch.title);
    const currentBranchTitle = params.get('branch');

    if (title === currentBranchTitle) {
      params.delete('branch');
      searchStore.setBranch({});
    } else {
      params.set('branch', title);
      searchStore.setBranch(branch);
    }

    history.push({ search: params.toString() });
    await getEntities();
  };

  const handlerOnClickCategory = (category) => {
    const params = new URLSearchParams(location.search);
    const { title } = category;
    const translatedTitle = translate(title);
    const currentCategoryTitle = params.get('category');

    if (title === currentCategoryTitle) {
      params.delete('category');
      searchStore.setActiveCategory({});
    } else {
      params.set('category', translatedTitle);
      searchStore.setActiveCategory(category);
    }

    history.push({ search: params.toString() });
    setFilterShops();
  };

  const handleClickSearch = () => {
    history.push({ pathname: `/${search.organisation}/search`, search: location.search });
  };

  const handleClickShop = async (destinationShop) => {
    await serviceStore.fetchRedirectLink(destinationShop);
  };

  return useObserver(() => (
    <Wrapper theme={themeStore.theme} className="search-result-screen">
      <InputBanner
        ref={keywordRef}
        value={searchStore.keyword}
        banner={publicUrl.join(organisationStore.banner.search)}
        label="Search shop name"
        labelVariant="light"
        placeholder="Search"
        iconRight="Search"
        onClick={handleClickSearch}
        readonly
      />

      <Collapse
        title="Branch"
        coverText={translate(searchStore.branch.title) || 'All branch'}
        renderContent={(
          <div className="ad-hoc-collapse-content-container">
            <BranchFilter
              branches={serviceStore.projects.map((project) => ({ ...project, img: publicUrl.join(organisationStore.project[project.slug].img) }))}
              active={searchStore.branch}
              activeKey="id"
              onClick={handlerOnClickBranch}
            />
          </div>
        )}
      />

      <Collapse
        title="Category"
        coverText={param.get('category') || 'All category'}
        renderContent={(
          <div className="ad-hoc-collapse-content-container">
            <CategoryFilter
              categories={searchStore.categories}
              activeCategory={searchStore.activeCategory}
              onClick={handlerOnClickCategory}
            />
          </div>
      )}
      />
      <h1 className="list-header">Shop name</h1>
      {loading && <h1>loading...</h1>}
      <ListContainer
        items={searchStore.shops}
        keyExtractor={(item) => item.id}
        render={(item) => <ShopListItem item={item} onClick={handleClickShop} />}
      />
    </Wrapper>
  ));
}

const Wrapper = styled.div`
  &.search-result-screen {
    .ad-hoc-collapse-content-container {
      background-color: ${({ theme }) => theme.backgroundVariant};
    }
  }

  ${({ theme }) => `
    color: ${theme.text};

    .shop-category, .list-header {
      border-bottom: 1px solid ${theme.border};
    }
  `}
`;

export default SearchShop;
