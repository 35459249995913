import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import cx from 'classnames';

import { useObserver } from 'mobx-react-lite';

import { useStore } from '~/hooks';

import Icon from '../Icon';

const variants = [ 'primary', 'info', 'light', 'dark' ];

/**
 * Button component
 *
 * @param {string} props.title
 * @param {function} props.onClick
 * @param {'primart' | 'info' | 'light' | 'dark'} props.variant
 * @param {boolean} props.rounded
 * @param {'small' | 'normal' | 'medium' | 'large'} props.size
 * @return {JSX.Element}
 */
function Button ({
  title,
  onClick,
  variant,
  rounded,
  size,
  leftIcon,
  rightIcon,
}) {
  const { themeStore } = useStore();

  return useObserver(() => (
    <Wrapper
      theme={themeStore.theme}
      onClick={onClick}
      className={cx(
        'button',
        { [`is-${variant}`]: variant },
        { 'is-rounded': rounded },
        { [`is-${size}`]: size },
      )}
    >
      {leftIcon && <Icon name={leftIcon} className="icon-left" />}
      {title}
      {rightIcon && <Icon name={rightIcon} className="icon-right" />}
    </Wrapper>
  ));
}

const Wrapper = styled.button`
  ${({ theme }) => `
    color: ${theme.light};
    border-color: ${theme.primary};
    background-color: ${theme.primary};
  `}

  ${({ theme }) => (
    variants.reduce((result, variant) => `
      ${result}
      &.is-${variant} {
        color: ${variant === 'light' ? theme.dark : theme.light};
        border-color: ${variant === 'light' ? theme.dark : theme[variant]};
        background-color: ${theme[variant]};
      }
    `, '')
  )}
`;

Button.propTypes = {
  title: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf([ ...variants, '' ]),
  rounded: PropTypes.bool,
  size: PropTypes.oneOf([ '', 'small', 'normal', 'medium', 'large' ]),
};

Button.defaultProps = {
  title: '',
  onClick () {},
  variant: '',
  rounded: false,
  size: '',
};

export default Button;
