import axios from 'axios';


/**
 * @typedef defaultOpts
 * @type {object}
 * @property {boolean} internal - it'll call to public folder if internal is true
 */
const defaultOpts = {
  internal: false,
};

/**
 * Fetcher promise based http client
 *
 * @param {any} props
 * @param {defaultOpts} opts
 */
function fetcher (props = {}, opts = defaultOpts) {
  const { PUBLIC_URL } = process.env;

  /**
   * Request config
   *
   * @constant
   * @see {@link https://github.com/axios/axios#request-config}
   */
  const config = {
    ...props,
    baseURL: opts.internal ? `${PUBLIC_URL}/meta` : props.baseURL,
  };

  /**
   * axios instance
   *
   * see more detail:
   * - creating axios instance {@link https://github.com/axios/axios#creating-an-instance}
   * - available instance methods {@link https://github.com/axios/axios#instance-methods}
   * - request config {@link https://github.com/axios/axios#request-config}
   * - response schema {@link https://github.com/axios/axios#request-config}
   */
  return axios.create(config);
}

export default fetcher;
