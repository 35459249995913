import React from 'react';
import PropTypes from 'prop-types';

import * as IonIcons from 'react-icons/io';
import cx from 'classnames';


/**
 * Icon component
 *
 * @param {string} props.name
 * @param {string} props.color
 * @param {'small' | 'normal' | 'medium' | 'large'}
 * @return {JSX.Element}
 */
function Icon ({ name, color, size }) {
  const Component = IonIcons[`IoIos${name}`];

  return (
    <Component
      color={color}
      className={cx(
        'icon',
        { [`is-${size}`]: size },
      )}
    />
  );
}

Icon.propTypes = {
  name: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.oneOf([ '', 'small', 'normal', 'medium', 'large' ]),
};

Icon.defaultProps = {
  name: '',
  color: '',
  size: '',
};

export default Icon;
