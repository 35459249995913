import React, { useReducer, useRef } from 'react';
import PropTypes from 'prop-types';

import { useObserver } from 'mobx-react-lite';

import Hammer from 'react-hammerjs';
import clamp from 'lodash.clamp';
import { useSpring, animated } from 'react-spring';
import cx from 'classnames';
import styled from '@emotion/styled';

import { useStore, useTranslate } from '~/hooks';

import { CategoryIcon } from '~/components';

const defaultCategory = [{ id: 0, title: 'all', img: 'https://via.placeholder.com/250' }];

const init = {
  current: 0, // current translate value
  translate: 0, // translate value for moving element
};

const reducer = (state, action) => {
  const { type, payload } = action;

  switch (type) {
    case 'pan': {
      const { translate } = payload;

      return { ...state, translate };
    }

    case 'pan-end': {
      const { translate } = payload;

      return { ...state, translate, current: translate };
    }

    default:
      throw new Error();
  }
};

/**
 * Category filter component
 *
 * @typedef {Object} Props
 * @property {any[]} categories
 * @property {boolean} active
 * @property {string} activeKey
 * @property {function} onClick
 *
 * @param {Props} props
 * @return {JSX.Element}
 */
function CategoryFilter ({ categories, activeCategory, onClick }) {
  const [ state, dispatch ] = useReducer(reducer, init);
  const filterListRef = useRef(null);
  const filterContainerRef = useRef(null);

  const { themeStore } = useStore();
  const { translate } = useTranslate();

  const [ animatedProps, setAnimated ] = useSpring(() => ({ transform: `translateX(${state.translate}px)` }));

  const handleOnPan = (e) => {
    const translate = state.current + e.deltaX;

    dispatch({ type: 'pan', payload: { translate } });
    setAnimated({ immediate: true, to: { transform: `translateX(${translate}px)` } });
  };

  const handleOnPanEnd = (e) => {
    const listWidth = filterListRef.current.clientWidth;
    const containerWidth = filterContainerRef.current.clientWidth;

    const result = state.current + e.deltaX;
    const translate = clamp(result, -(listWidth - containerWidth), 0);

    dispatch({ type: 'pan-end', payload: { translate } });
    setAnimated({ immediate: false, to: { transform: `translateX(${translate}px)` } });
  };

  const renderFilterItem = (category, index) => (
    <CategoryIcon
      key={category.slug || index.toString()}
      title={translate(category.title)}
      categoryKey={category.slug || index.toString()}
      active={activeCategory.slug === category.slug}
      onClick={() => onClick(category, index)}
    />
  );

  return useObserver(() => (
    <Wrapper ref={filterContainerRef} theme={themeStore.theme} className="category-filter">
      <div className="filter-ref-wrapper" ref={filterListRef}>
        <Hammer
          onPan={handleOnPan}
          onPanEnd={handleOnPanEnd}
        >
          <animated.div
            style={animatedProps}
            className={cx(
              'filter-item-container',
              { 'is-panning': state.isPanning },
            )}
          >
            {[ ...defaultCategory, ...categories ].map((category, index) => renderFilterItem(category, index))}
          </animated.div>
        </Hammer>
      </div>
    </Wrapper>
  ));
}

CategoryFilter.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape()),
  active: PropTypes.shape(),
  activeKey: PropTypes.string,
  onClick: PropTypes.func,
};

CategoryFilter.defaultProps = {
  categories: [],
  active: {},
  activeKey: 'id',
  onClick () {},
};

const Wrapper = styled.div`
  &.category-filter {
    .filter-item {
      &.is-active {
        .filter-item-icon {
          border: 1px solid ${({ theme }) => theme.primary};
        }
      }
    }
  }
`;

export default CategoryFilter;
