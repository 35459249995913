import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';
import cx from 'classnames';

import { useObserver } from 'mobx-react-lite';

import { useStore } from '~/hooks';
/**
 * ListContainer component
 *
 * @param {Array} items
 * @param {Function} keyExtractor
 * @param {Function} render
 * @param {string} injectContainerClass
 * @param {Function} renderSeparator
 * @return {JSX.Element}
 */
function ListContainer ({
  items,
  keyExtractor,
  render,
  injectContainerClass,
  renderSeparator,
  renderHeader,
  renderFooter,
}) {
  const { themeStore } = useStore();

  return useObserver(() => (
    <Wrapper theme={themeStore.theme}>
      <div className={cx('list', injectContainerClass)}>
        {renderHeader()}
        {items.map((item, index) => (
          <Fragment key={keyExtractor(item, index)}>
            {render(item, index)}
            {index !== items.length - 1 && renderSeparator()}
          </Fragment>
        ))}
        {renderFooter()}
      </div>
    </Wrapper>
  ));
}

const Wrapper = styled.div`
  ${({ theme }) => `
    .list {
      .separator {
        background-color: ${theme.border};
      }
    }
  `}
`;

ListContainer.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape()),
  render: PropTypes.func,
  keyExtractor: PropTypes.func,
  injectContainerClass: PropTypes.string,
  renderSeparator: PropTypes.func,
  renderHeader: PropTypes.func,
  renderFooter: PropTypes.func,
};

ListContainer.defaultProps = {
  items: [],
  render () { },
  keyExtractor () { },
  injectContainerClass: '',
  renderSeparator () { },
  renderHeader () { },
  renderFooter () { },
};

export default ListContainer;
