import React from 'react';
import PropTypes from 'prop-types';
import HighligtText from '../HighlightText';

function ShopItem ({
  title,
  category,
  onClick,
  text,
}) {
  const handleKeyPress = () => {};
  return (
    <div
      role="menuitem"
      tabIndex="0"
      onKeyPress={handleKeyPress}
      onClick={(e) => onClick(e, title, category)}
      className="shop-item"
    >
      <h1 className="title">
        <HighligtText text={title} highlight={text} />
      </h1>
      <h1 className="category">{category}</h1>
    </div>
  );
}

ShopItem.propTypes = {
  title: PropTypes.string,
  category: PropTypes.string,
  onClick: PropTypes.func,
  text: PropTypes.string,
};

ShopItem.defaultProps = {
  title: '',
  category: '',
  onClick () {},
  text: '',
};

export default ShopItem;
