import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Helmet from 'react-helmet';
import { useObserver } from 'mobx-react-lite';
import { Switch } from 'react-router-dom';
import styled from '@emotion/styled';

import { useStore, useLIFF } from '~/hooks';
import config from '~/lib/Config';
import fetcher from '~/lib/Fetcher';

const getParameter = () => {
  const { pathname } = window.location;
  const organisation = pathname.split('/')[1];
  if (!organisation) alert('Organisation not found. Example localhost/terminal21');
  return {
    organisation,
  };
};

/**
 * Loading switch component
 *
 * @param {any} children
 * @param {func} renderLoading
 * @return {JSX.Element}
 */
function LoadingSwitch ({ children, renderLoading }) {
  const [ loading, setLoading ] = useState(true);
  const { organisation } = getParameter();

  // const liffId = organisation === 'terminal21' ? config.TERMINAL_21_LIFF_ID : config.FASHIONISLAND_LIFF_ID;
  // const liff = useLIFF(liffId);

  const { organisationStore, themeStore, serviceStore } = useStore();

  const configServiceStore = (target) => {
    switch (target) {
      case 'terminal21': {
        const { TERMINAL_21_URL, TERMINAL_21_ASOK_API_KEY, TERMINAL_21_KORAT_API_KEY, TERMINAL_21_PATTAYA_API_KEY, TERMINAL_21_RAMA3_API_KEY, TERMINAL_21_STAT_SERVICE } = config;

        return {
          fetcher: fetcher({ baseURL: TERMINAL_21_URL }),
          utmFetcher: fetcher({ baseURL: TERMINAL_21_STAT_SERVICE }),
          keys: {
            'terminal21-asok': TERMINAL_21_ASOK_API_KEY,
            'terminal21-korat': TERMINAL_21_KORAT_API_KEY,
            'terminal21-pattaya': TERMINAL_21_PATTAYA_API_KEY,
            'terminal21-rama3': TERMINAL_21_RAMA3_API_KEY,

          },
        };
      }

      case 'fashionisland': {
        const { FASHIONISLAND_URL, THE_PROMENADE_API_KEY, FASHIONISLAND_API_KEY, FASHONISLAND_STAT_SERVICE } = config;

        return {
          fetcher: fetcher({ baseURL: FASHIONISLAND_URL }),
          utmFetcher: fetcher({ baseURL: FASHONISLAND_STAT_SERVICE }),
          keys: { thepromenade: THE_PROMENADE_API_KEY, fashionisland: FASHIONISLAND_API_KEY },
        };
      }

      default:
        throw new Error();
    }
  };


  useEffect(() => {
    const init = async () => {
      organisationStore.init(fetcher(null, { internal: true }));
      themeStore.init(fetcher(null, { internal: true }));
      serviceStore.init(configServiceStore(organisation));

      await organisationStore.fetchOrganisation(organisation);
      await themeStore.fetchTheme(organisation);

      // try {
      //   await liff.init();
      //   if (liff.isLoggedIn()) {
      //     const profile = await liff.getProfile();
      //     serviceStore.setLineProfile(profile);
      //     serviceStore.createUtm({
      //       source: 'line',
      //       medium: 'richmenu',
      //       campaign: 'richmenu_tracking',
      //       content: 'shopsearch',
      //     });
      //   } else {
      //     liff.login();
      //   }
      // } catch (error) {
      //   console.error(error);
      // }

      setLoading(false);
    };

    init();
  }, []);

  const renderSwitch = () => (
    <Switch>
      {children}
    </Switch>
  );

  const render = () => (
    renderLoading
      ? renderLoading()
      : (
        <div>
          <h1>Loading...</h1>
        </div>
      )
  );

  return useObserver(() => (
    <Wrapper className="loading-switch">
      {loading ? render() : renderSwitch()}
      {
        organisationStore.helmet && (
          <Helmet>
            <meta charSet="utf-8" />
            <link rel="shortcut icon" href={organisationStore.helmet.favicon} type="image/x-icon" />
            <link rel="icon" href={organisationStore.helmet.favicon} type="image/x-icon" />
            <title>{organisationStore.helmet.title}</title>
          </Helmet>
        )
      }
    </Wrapper>
  ));
}

LoadingSwitch.propTypes = {
  children: PropTypes.node.isRequired,
  renderLoading: PropTypes.func,
};

LoadingSwitch.defaultProps = {
  renderLoading: undefined,
};

const Wrapper = styled.div``;

export default LoadingSwitch;
