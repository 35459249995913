import React, { useEffect, useRef } from 'react';

import { useObserver } from 'mobx-react-lite';
import { useHistory, useLocation } from 'react-router-dom';
import styled from '@emotion/styled';
import isEmpty from 'lodash.isempty';

import { useStore, useTranslate } from '~/hooks';
import publicUrl from '~/lib/PublicUrl';

import { Banner, Section, Field, Button } from '~/components';
import { BranchFilter } from '~/explicit-components';

/**
 * Home screen
 *
 * @return {JSX.Element}
 */
function Home () {
  const keywordRef = useRef(null);

  const location = useLocation();
  const history = useHistory();

  const { themeStore, searchStore, organisationStore, serviceStore } = useStore();
  const { translate } = useTranslate();

  const handlerOnClickBranch = (branch) => {
    const params = new URLSearchParams(location.search);
    const title = translate(branch.title);
    const currentBranchTitle = params.get('branch');
    if (title === currentBranchTitle) {
      params.delete('branch');
      searchStore.setBranch({});
    } else {
      params.set('branch', title);
      searchStore.setBranch(branch);
    }
    history.push({ search: params.toString() });
  };

  const handleClickSearch = () => {
    history.push({
      pathname: `${location.pathname}/search/result`,
      search: location.search,
    });
  };

  const init = async () => {
    const projects = await serviceStore.fetcherProjects();

    const params = new URLSearchParams(location.search);
    const keyword = params.get('keyword');
    const branch = params.get('branch');

    if (keywordRef && keywordRef.current) {
      keywordRef.current.value = keyword || searchStore.keyword;
      if (keyword && !searchStore.keyword) searchStore.setKeyword(keyword);
      if (branch && isEmpty(searchStore.branch)) {
        searchStore.setBranch(projects.find((project) => translate(project.title) === branch) || searchStore.branch);
      }
    }
  };

  useEffect(() => {
    init();
  }, []);

  return useObserver(() => (
    <Wrapper theme={themeStore.theme} className="home">
      <Section marginTopless paddingless>
        <Banner img={publicUrl.join(organisationStore.banner.home)} />
      </Section>
      <Section>
        <BranchFilter
          title="Select branch"
          branches={serviceStore.projects.map((project) => ({ ...project, img: publicUrl.join(organisationStore.project[project.slug].img) }))}
          active={searchStore.branch}
          activeKey="id"
          onClick={handlerOnClickBranch}
        />
      </Section>
      <Section>
        <Field>
          <Field.Label>Search shop name</Field.Label>
          <div
            role="button"
            tabIndex="0"
            onKeyPress={() => {}}
            onClick={() => history.push({ pathname: `${location.pathname}/search`, search: location.search })}
          >
            <Field.Input
              ref={keywordRef}
              placeholder="Search"
              readonly
            />
          </div>
        </Field>
      </Section>
      <Section centeredAlign>
        <Button
          title="SEARCH"
          onClick={handleClickSearch}
          rounded
        />
      </Section>
    </Wrapper>
  ));
}

const Wrapper = styled.div``;

export default Home;
