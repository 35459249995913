import { observable, action } from 'mobx';


class SearchStore {
  @observable
  keyword = '';

  @observable
  branch = {};

  @observable
  activeCategory = {};

  @observable
  searches = [];

  @observable
  shops = [];

  @observable
  categories = [];

  @action.bound
  hasBranch () {
    return Object.keys(this.branch).length !== 0;
  }

  @action.bound
  hasActiveCategory () {
    return Object.keys(this.activeCategory).length !== 0;
  }

  @action.bound
  setKeyword (keyword) {
    this.keyword = keyword;
  }

  @action.bound
  setBranch (branch) {
    this.branch = branch;
  }

  @action.bound
  setActiveCategory (category) {
    this.activeCategory = category;
  }

  @action.bound
  setShops (shops) {
    this.shops = shops;
  }

  @action.bound
  setSearches (searches) {
    this.searches = searches;
  }

  @action.bound
  setCategories (categories) {
    this.categories = categories;
  }
}

export default {};
export { SearchStore };
