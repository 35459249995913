import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';

import Icon from '../../Icon';


/**
 * Input of Field component
 *
 * @param {string} props.value
 * @param {string} props.defaultValue
 * @param {'small' | 'normal' | 'medium' | 'large'} props.size
 * @param {string} props.placeholder
 * @param {function} props.onChange
 * @param {function} props.onKeyDown
 * @param {function} props.onKeyUp
 * @param {function} props.onClickIconLeft
 * @param {function} props.onClickIconRight
 * @param {JSX.Element} props.iconLeft
 * @param {JSX.Element} props.iconRight
 * @param {boolean} props.borderless
 * @param {boolean} props.readonly
 * @return {JSX.Element}
 */
const Input = forwardRef(({
  value,
  defaultValue,
  size,
  placeholder,
  onChange,
  onKeyDown,
  onKeyUp,
  onClickIconLeft,
  onClickIconRight,
  iconLeft,
  iconRight,
  borderless,
  readonly,
}, ref) => {
  const renderIcon = (name, position, onClick) => (
    <div
      onClick={onClick}
      onKeyPress={() => {}}
      role="button"
      tabIndex="0"
      className={cx(
        'input-icon',
        { [`is-${position}`]: position },
        { 'is-clickable': onClick },
      )}
    >
      <Icon name={name} size={size} />
    </div>
  );

  return (
    <div
      className={cx(
        'control',
        { 'has-icon-left': iconLeft },
        { 'has-icon-right': iconRight },
      )}
    >
      <input
        ref={ref}
        type="text"
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onKeyUp={onKeyUp}
        className={cx(
          'input',
          { [`is-${size}`]: size },
          { 'is-borderless': borderless },
        )}
        readOnly={readonly}
      />
      {iconLeft && renderIcon(iconLeft, 'left', onClickIconLeft)}
      {iconRight && renderIcon(iconRight, 'right', onClickIconRight)}
    </div>
  );
});

Input.propTypes = {
  value: PropTypes.string,
  defaultValue: PropTypes.string,
  size: PropTypes.oneOf([ '', 'small', 'normal', 'medium', 'large' ]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onKeyDown: PropTypes.func,
  onKeyUp: PropTypes.func,
  onClickIconLeft: PropTypes.func,
  onClickIconRight: PropTypes.func,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  borderless: PropTypes.bool,
  readonly: PropTypes.bool,
};

Input.defaultProps = {
  value: undefined,
  defaultValue: undefined,
  size: '',
  placeholder: '',
  onChange () {},
  onKeyDown () {},
  onKeyUp () {},
  onClickIconLeft () {},
  onClickIconRight () {},
  iconLeft: '',
  iconRight: '',
  borderless: false,
  readonly: false,
};

export default Input;
