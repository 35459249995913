import { Service } from './base/Service';


class CategoryService extends Service {
  /**
   * Constructor
   *
   * @constructor
   * @param {any} fetcher
   * @param {string} [url=/api/v1/projects]
   */
  constructor (fetcher, url = '/categories') {
    super(fetcher, url);
  }
}

export default {};
export { CategoryService };
