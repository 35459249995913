import { observable, action } from 'mobx';


class LanguageStore {
  @observable
  language = '';

  /**
   * Constructor
   *
   * @constructor
   * @param {'en' | 'th'} language
   */
  constructor (language) {
    this.language = language;
  }

  @action.bound
  setLanguage (language) {
    this.language = language;
  }
}

export default {};
export { LanguageStore };
