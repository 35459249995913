import React from 'react';
import PropTypes from 'prop-types';

import cx from 'classnames';
import styled from '@emotion/styled';


/**
 * Container component
 *
 * @return {JSX.Element}
 */
function Container ({ children, fluid }) {
  return (
    <Wrapper
      className={cx(
        'container',
        { 'is-fluid': fluid },
      )}
    >
      {children}
    </Wrapper>
  );
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
  fluid: PropTypes.bool,
};

Container.defaultProps = {
  fluid: false,
};

const Wrapper = styled.div`
  &.container {}
`;

export default Container;
