import { observable, action, runInAction } from 'mobx';

import { ThemeService } from '~/services/ThemeService';


class ThemeStore {
  @observable
  theme = {};

  @action.bound
  async fetchTheme (target) {
    try {
      const { data } = await this.themeService.get(target);

      runInAction(() => {
        this.theme = data;
      });

      return data;
    } catch (error) {
      throw new Error(error);
    }
  }

  @action.bound
  init (fetcher) {
    this.themeService = new ThemeService(fetcher);
  }
}

export default {};
export { ThemeStore };
