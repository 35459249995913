import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import cx from 'classnames';
import { useObserver } from 'mobx-react-lite';

import { useStore } from '~/hooks';

/**
 * ----------------------------------------------------------------------------
 * Category icon component
 * ----------------------------------------------------------------------------
 *
 * @return {JSX.Element}
 */
function CategoryIcon ({ title, categoryKey, active, onClick }) {
  const { themeStore } = useStore();
  const { theme } = themeStore;

  const categoryIcons = theme.categoryIcons || {};
  const targetCategoryKey = Object.keys(categoryIcons).find((key) => {
    const regex = new RegExp(key, 'ig');
    return regex.test(categoryKey);
  });
  const targetCategorySrc = targetCategoryKey ? categoryIcons[targetCategoryKey] : '';
  if (!targetCategorySrc) return <></>;
  return useObserver(() => (
    <Wrapper
      theme={theme}
      key={categoryKey}
      className={cx(
        'filter-item',
        { 'is-active': active },
      )}
      onClick={onClick}
    >
      <img className="filter-item-icon" src={targetCategorySrc} alt="" />
      <p className="filter-item-title">{title}</p>
    </Wrapper>
  ));
}

CategoryIcon.propTypes = {
  title: PropTypes.string,
  categoryKey: PropTypes.string.isRequired,
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

CategoryIcon.defaultProps = {
  title: '',
  active: false,
  onClick () {},
};

const Wrapper = styled.div``;

export default CategoryIcon;
