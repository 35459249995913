import React, { useState, useRef, useEffect } from 'react';

import styled from '@emotion/styled';
import { useHistory, useLocation } from 'react-router-dom';

import { useObserver } from 'mobx-react-lite';
import uniqBy from 'lodash.uniqby';

import { useStore, useComponentDidMount, useTranslate } from '~/hooks';
import { Field, Container, ListContainer } from '~/components';
import { SearchResultItem } from '~/explicit-components';

import SearchGraphic from '~/assets/images/search_graphic.png';

/**
 * Search screen
 *
 * @return {JSX.Element}
 */
function Search () {
  const searchBarRef = useRef(null);
  const timeoutRef = useRef(null);

  const [ input, setInput ] = useState('');
  const [ isTyping, setIsTyping ] = useState(false);

  const { themeStore, searchStore, serviceStore } = useStore();
  const { translate } = useTranslate();

  const history = useHistory();
  const location = useLocation();

  const handleSearchKeyword = (search) => {
    const params = new URLSearchParams(location.search);

    searchStore.setKeyword(search);
    params.set('keyword', search);

    history.push({ pathname: `${location.pathname}/result`, search: params.toString() });
  };

  const handlerSubmitSearch = (e) => {
    // if press enter, it will navigate to search result screen
    if (e.keyCode === 13 && serviceStore.entities.length > 0) {
      handleSearchKeyword(input);
    }
  };

  const handleClearSearchText = () => {
    setInput(() => {
      setIsTyping(false);
      if (searchBarRef && searchBarRef.current) {
        searchBarRef.current.value = '';
        searchBarRef.current.focus();
      }
      return '';
    });
  };

  const handlerOnChange = async (text) => {
    setInput(() => {
      setIsTyping(true);
      if (searchBarRef && searchBarRef.current) {
        searchBarRef.current.value = text;
        searchBarRef.current.focus();
      }
      return text;
    });
  };

  const handleClickIconRight = () => {
    if (input.length > 0) {
      handleClearSearchText();
    }
  };

  const renderKeywordItem = (item) => (
    <SearchResultItem
      item={item}
      searchValue={input}
      onClick={() => handleSearchKeyword(translate(item.title))}
      onClickIconRight={() => handlerOnChange(translate(item.title))}
    />
  );

  const getEntities = async () => {
    await serviceStore.fetchEntities(searchStore.branch, { type: 'shops', keywords: [input], $populate: [ 'logo', 'unit', 'project', 'categories', 'floor' ] });
    setIsTyping(false);
  };

  useComponentDidMount(() => {
    if (timeoutRef.current) clearTimeout(timeoutRef.current);
    if (input) {
      timeoutRef.current = setTimeout(() => {
        getEntities();
      }, 400);
    } else {
      setIsTyping(false);
      serviceStore.removeEntities();
    }
  }, [input]);

  useEffect(() => {
    searchStore.setSearches(serviceStore.entities);
  }, [serviceStore.entities]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const keyword = params.get('keyword');
    searchBarRef.current.value = keyword || '';
    searchBarRef.current.focus();
    if (keyword) {
      setIsTyping(true);
      setInput(keyword);
    }
  }, []);

  return useObserver(() => (
    <Wrapper theme={themeStore.theme} className="search-screen">
      <Container fluid>
        <Field>
          <Field.Input
            ref={searchBarRef}
            iconLeft="ArrowBack"
            iconRight={input.length > 0 ? 'CloseCircle' : 'Search'}
            placeholder="Search or type shop name or keywords"
            onClickIconLeft={() => history.goBack()}
            onClickIconRight={handleClickIconRight}
            onKeyUp={handlerSubmitSearch}
            onChange={({ target }) => handlerOnChange(target.value)}
            borderless
          />
        </Field>
      </Container>

      <Container>
        {((input && serviceStore.entities.length === 0) || isTyping) && <p style={{ fontWeight: 'bold' }}>{`Shops with "${input}"`}</p>}
        {(!isTyping && serviceStore.entities.length > 0) ? (
          <ListContainer
            items={uniqBy(searchStore.searches, 'title.en')}
            keyExtractor={(item) => item.id.toString()}
            render={renderKeywordItem}
          />
        ) : (
          <div className="graphic-wrapper">
            <img src={SearchGraphic} alt="search" />
          </div>
        )}
      </Container>
    </Wrapper>
  ));
}

const Wrapper = styled.div``;

export default Search;
