import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import { useStore } from '~/hooks';

import Label from './components/Label';
import Input from './components/Input';


/**
 * Field components
 *
 * @param {JSX.Element} props.children
 * @return {JSX.Element}
 */
function Field ({ children }) {
  const { themeStore } = useStore();

  return (
    <Wrapper theme={themeStore.theme} className="field">
      {children}
    </Wrapper>
  );
}

Field.propTypes = {
  children: PropTypes.node.isRequired,
};

Field.defaultProps = {};

const Wrapper = styled.div`
  &.field {
    .label {
      &.is-primary {
        color: ${({ theme }) => theme.primary};
      }

      &.is-info {
        color: ${({ theme }) => theme.info};
      }

      &.is-light {
        color: ${({ theme }) => theme.light};
      }

      &.is-dark {
        color: ${({ theme }) => theme.dark};
      }
    }

    .control {
      .input {
        background-color: ${({ theme }) => theme.background};
        border-color: ${({ theme }) => theme.border};
        color: inherit;

        &::placeholder {
          color: ${({ theme }) => theme.textHelper};
        }
      }
    }
  }
`;

Field.Label = Label;
Field.Input = Input;

export default Field;
