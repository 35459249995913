import { observable, action, runInAction } from 'mobx';

import isEmpty from 'lodash.isempty';

import { EntityService } from '~/services/EntityService';
import { ProjectService } from '~/services/ProjectService';
import { CategoryService } from '~/services/CategoryService';
import { RedirectService } from '~/services/RedirectService';
import { UtmService } from '~/services/UtmService';

class ServiceStore {
  @observable
  // API key
  // using as an object which contain <project-slug, api-key> for reason of handle multiple project request such as terminal21 asok, korat, and pattaya (3 projects)
  keys = {}

  @observable
  loading = false;

  @observable
  error = {};

  @observable
  entities = [];

  @observable
  projects = [];

  @observable
  categories = [];

  @observable
  redirectLink = '';

  @observable
  lineProfile = {};

  /**
   * Constructor
   *
   * @constructor
   * @param {any} fetcher
   */
  constructor (fetcher) {
    this.fetcher = fetcher;
  }

  getUniqueCategories = () => this.categories;

  getProjectSlugs = () => Object.keys(this.keys);

  getApiKeys = () => Object.values(this.keys);

  getApiKeyByProjectSlug = (projectSlug) => {
    const key = this.keys[projectSlug];
    return key || '';
  }

  @action.bound
  async fetchEntities (branch, params, headers) {
    this.loading = true;
    try {
      let apiKeys = [];

      if (branch && branch.slug) {
        apiKeys = [this.getApiKeyByProjectSlug(branch.slug)];
      } else {
        apiKeys = this.getApiKeys();
      }

      const responses = await Promise.all(apiKeys.reduce((acc, key) => [
        ...acc,
        this.entityService.get(params, { ...headers, key }),
      ], []));

      const data = responses.reduce((acc, response) => [ ...acc, ...response.data ], []);

      runInAction(() => {
        this.loading = false;
        this.entities = data;
      });

      return data;
    } catch (error) {
      runInAction(() => {
        this.loading = false;
        this.error = error;
      });

      throw new Error(error);
    }
  }

  @action.bound
  async fetcherProjects (params, headers) {
    this.loading = true;

    if (!isEmpty(this.projects)) {
      this.loading = false;
      return this.projects;
    }

    try {
      const responses = await Promise.all(this.getApiKeys().map((key) => this.projectService.get(params, { ...headers, key })));

      const data = responses.reduce((result, res) => [ ...result, ...res.data ], []).sort((a, b) => a.id - b.id);

      runInAction(() => {
        this.loading = false;
        this.projects = data;
      });

      return data;
    } catch (error) {
      runInAction(() => {
        this.loading = false;
        this.error = error;
      });

      throw error;
    }
  }

  @action.bound
  async fetchCategories (branch, params, headers) {
    this.loading = true;
    try {
      let apiKeys = [];
      if (branch && branch.slug) {
        apiKeys = [this.getApiKeyByProjectSlug(branch.slug)];
      } else {
        apiKeys = this.getApiKeys();
      }
      const responses = await (Promise.all(apiKeys.reduce((acc, key) => [
        ...acc,
        this.categoryService.get(params, { ...headers, key }),
      ], [])));
      const data = responses.reduce((acc, response) => [ ...acc, ...response.data ], []);
      runInAction(() => {
        this.loading = false;
        this.categories = data;
      });

      return data;
    } catch (error) {
      runInAction(() => {
        this.loading = false;
        this.error = error;
      });

      throw new Error(error);
    }
  }

  @action.bound
  async fetchRedirectLink (destinationShop) {
    this.loading = true;
    try {
      const { data } = await this.redirectService.post(
        {
          language: 'en',
          project: destinationShop.projectId,
          destination: destinationShop.id,
        },
        { response: 'json' },
      );
      window.location.href = data.link;
      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
        this.error = error;
      });

      throw new Error(error);
    }
  }

  @action.bound
  setLineProfile (lineProfile) {
    this.lineProfile = lineProfile;
  }

  @action.bound
  async createUtm ({ source, medium, campaign, content }) {
    this.loading = true;
    try {
      await this.utmService.create(
        {
          source,
          medium,
          campaign,
          content,
          subject: this.lineProfile.userId,
        },
      );
      runInAction(() => {
        this.loading = false;
      });
    } catch (error) {
      runInAction(() => {
        this.loading = false;
        this.error = error;
      });

      throw new Error(error);
    }
  }

  @action.bound
  removeEntities () {
    this.entities = [];
  }

  @action.bound
  init ({ fetcher, utmFetcher, keys }) {
    this.keys = keys;
    this.entityService = new EntityService(fetcher);
    this.projectService = new ProjectService(fetcher);
    this.categoryService = new CategoryService(fetcher);
    this.redirectService = new RedirectService(fetcher);
    this.utmService = new UtmService(utmFetcher);
  }
}

export default {};
export { ServiceStore };
