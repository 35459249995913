import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import { useStore } from '~/hooks';


/**
 * Banner component
 *
 * @param {string} props.img
 * @return {JSX.Element}
 */
function Banner ({ img }) {
  const { themeStore } = useStore();

  return (
    <Wrapper theme={themeStore.theme} className="banner">
      <img src={img} alt={img} className="banner-img" />
    </Wrapper>
  );
}

Banner.propTypes = {
  img: PropTypes.string,
};

Banner.defaultProps = {
  img: '',
};

const Wrapper = styled.div``;

export default Banner;
