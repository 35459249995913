class EntityService {
  /**
   * Constructor
   */
  constructor (fetcher) {
    this.fetcher = fetcher;
    this.url = '/entities';
  }

  async get (params, headers) {
    const { url } = this;
    console.debug('EntityService', { url, params, headers });
    return this.fetcher.get(url, { params, headers });
  }
}

export default {};
export { EntityService };
