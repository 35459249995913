import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { useObserver } from 'mobx-react-lite';

import cx from 'classnames';
import { useClickOutside, useStore } from '~/hooks';

import Field from '../Field';
import ListContainer from '../ListContainer';
import ShopItem from '../ShopItem';

function InputDropdown ({
  items,
  label,
  onClickItem,
  onTextChange,
  iconLeft,
  iconRight,
  placeholder,
}) {
  const { themeStore } = useStore();
  const [ visible, setVisible ] = useState(false);
  const [ text, setText ] = useState('');
  const [ inputTimeout, setInputTimeout ] = useState(0);

  const ref = useRef();

  const regexp = new RegExp(`^${text.toLowerCase()}`, 'i');

  const handleOnChange = (text) => {
    if (inputTimeout) clearTimeout(inputTimeout);
    setText(text);

    setInputTimeout(setTimeout(() => {
      onTextChange(text);
    }, 400));
  };

  const handleOnClickItem = (title) => {
    setVisible(() => {
      setText(title);
      onClickItem(title);

      return false;
    });
  };

  useClickOutside(ref, () => setVisible(false));

  return useObserver(() => (
    <Wrapper theme={themeStore.theme} className="input-dropdown">
      <Field>
        { label && <Field.Label>{label}</Field.Label> }
        <Field.Input
          value={text}
          placeholder={placeholder}
          onChange={({ target }) => handleOnChange(target.value)}
          onKeyDown={() => setVisible(true)}
          iconLeft={iconLeft}
          iconRight={iconRight}
        />
      </Field>
      <div className="input-dropdown" ref={ref}>
        <div
          className={cx(
            'input-dropdown-content',
            { 'is-active': visible },
          )}
        >
          <ListContainer
            items={items.filter((item) => regexp.test(item.title.toLowerCase()))}
            keyExtractor={(item) => item.id}
            renderSeparator={() => <div className="separator" />}
            render={(item) => (
              <ShopItem
                title={item.title}
                category={item.category}
                onClick={(e, title) => handleOnClickItem(title)}
                text={text}
              />
            )}
          />
        </div>
      </div>
    </Wrapper>
  ));
}

const Wrapper = styled.div`
  ${({ theme }) => `
    .input-dropdown {
      &-content {
        border: 0.25px solid ${theme.border};
        background-color: ${theme.background};
      }
    }
  `}
`;

InputDropdown.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape),
  label: PropTypes.string,
  onClickItem: PropTypes.func,
  onTextChange: PropTypes.func,
  iconLeft: PropTypes.string,
  iconRight: PropTypes.string,
  placeholder: PropTypes.string,
};

InputDropdown.defaultProps = {
  items: [],
  label: '',
  onClickItem () {},
  onTextChange () {},
  iconLeft: '',
  iconRight: '',
  placeholder: '',
};

export default InputDropdown;
