import React from 'react';
import PropTypes from 'prop-types';

import styled from '@emotion/styled';

import { useStore, useTranslate } from '~/hooks';

import Button from '~/components/Button';
import Image from '~/components/Image';

/**
 * Shop list item component
 *
 * @return {JSX.Element}
 */
function ShopListItem ({ item, onClick }) {
  const { logo, title, floor, project, categories, tag } = item;
  const { slug } = project;

  const { themeStore } = useStore();
  const { translate } = useTranslate();

  const getShopCategory = () => (
    (item && categories && categories[categories.length - 1]) ? translate(categories[categories.length - 1]) : ''
  );

  return (
    <Wrapper theme={themeStore.theme}>
      <div
        className="shop-list-item"
        onClick={() => floor && onClick(item)}
        onKeyPress={() => {}}
        role="menu"
        tabIndex="0"
      >
        <div className="prefix">
          {tag && <div className="tag-index"><span className="text">{tag}</span></div>}
        </div>
        <div className="avatar">
          <Image className="img-avatar" src={logo} alt="" projectSlug={slug} />
        </div>
        <div className="title-texts">
          <div className="title"><span>{translate(title)}</span></div>
          <div className="subtitle is-text-gray-light"><span>{getShopCategory()}</span></div>
        </div>
        <div className="detail-texts">
          {
            floor && (
              <div className="floor">
                <span>{(floor && translate(floor)) || ''}</span>
                <Button
                  onClick={() => null}
                  variant="primary"
                  rounded
                  size="small"
                  leftIcon="Walk"
                />
              </div>
            )
          }
          <div className="branch is-text-gray-light"><span>{translate(project.title)}</span></div>
        </div>
        <div className="suffix" />
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  ${({ theme }) => `
    .index{
      .tag-index {
        background-color: ${theme.primary};

        span {
          color: ${theme.textLight};
        }
      }
    }
    .avatar {
      .img-avatar {
        border: 1px solid ${theme.border};
      }
    }
  `}
`;

ShopListItem.propTypes = {
  item: PropTypes.shape({
    logo: PropTypes.string,
    title: PropTypes.shape({
      en: PropTypes.string,
      th: PropTypes.string,
    }),
    floor: PropTypes.shape({
      en: PropTypes.string,
      th: PropTypes.string,
    }),
    project: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      title: PropTypes.shape({
        en: PropTypes.string,
        th: PropTypes.string,
      }),
    }),
    categories: PropTypes.arrayOf(PropTypes.shape({
      title: PropTypes.shape({
        en: PropTypes.string,
        th: PropTypes.string,
      }),
    })),
    tag: PropTypes.string,
  }),
  onClick: PropTypes.func,
};

ShopListItem.defaultProps = {
  item: {},
  onClick () {},
};

export default ShopListItem;
