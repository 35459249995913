import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import isEmpty from 'lodash.isempty';

import { useRouteMatch } from 'react-router-dom';

import { getFile } from '~/lib';

const sanitizedSrc = (src) => {
  if (isEmpty(src)) return 'https://notfound.org';
  return src;
};

/**
 * ----------------------------------------------------------------------------
 * Img component
 * ----------------------------------------------------------------------------
 *
 * @param {{ src: String, fallbackSrc: String }} props
 * @param {String} props.src
 * @param {String} props.fallbackSrc
 * @return {JSX.Element}
 */
function Img ({ src, fallbackSrc, className, projectSlug }) {
  const { params } = useRouteMatch('/:organisation');
  const { organisation } = params;
  const [ image, setImage ] = useState(sanitizedSrc(src));
  useEffect(() => {
    setImage(sanitizedSrc(src));
  }, [src]);
  const handleOnError = () => {
    setImage(fallbackSrc || getFile(`/images/${organisation}/${projectSlug}-shop-no-logo.png`));
  };
  return (
    <Wrapper
      className={`img ${className}`}
      src={image}
      onError={handleOnError}
    />
  );
}

Img.propTypes = {
  src: PropTypes.string.isRequired,
  fallbackSrc: PropTypes.string,
  className: PropTypes.string,
  projectSlug: PropTypes.string,
};

Img.defaultProps = {
  fallbackSrc: '',
  className: '',
  projectSlug: 'fashionisland',
};

const Wrapper = styled.img`
  background: hsl(0, 0%, 71%);
`;

export default Img;
